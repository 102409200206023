html {
    font-size: 62.5%;
}

body {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    background-color: $color-bg;
    color: $color-main;
}

a {
    color: $color-white;
    text-decoration: none;
    &:hover {
        color: $color-main;
    }
}

button {
    background: transparent;
    box-shadow: none;
    border: none;
}

.nav {
    color: $color-white;
    background-color: $color-bg;
    position: fixed;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;

    &:hover {
        color: $color-main;
    }

    .nav__icon--i {
        display: block;
    }

    .nav__icon--x {
        display: none;
    }

    &.active {
        .nav__icon--i {
            display: none;
        }

        .nav__icon--x {
            display: block;
        }
    }

    @media screen and (max-width: 550px) {
        top: 35px;
    }
}

.nav__icon {
    width: 30px;
    height: 30px;

    @media screen and (max-width: 550px) {
        width: 35px;
        height: 35px;
    }
}

.logo-text-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include flexbox(column, wrap, center, center);
}

.main-text__bold {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    display: block;
}

.logo{
    @include flexbox(row, wrap, center, center);
    svg {
        color: $color-bg;
        fill: currentColor; 
        stroke: currentColor; 
        stroke-miterlimit:10;
        width: 297.33px;
        height: 297.33px;

        .pop-up & {
            width: 50%;
            height: auto;
        }
    }

    .font {
        path {
            fill: $color-white;
            stroke: $color-white;
        }
    }

    .pop-up & {
        display: block;
        margin-left: -7%;
    }
}

main {
    height: 100vh;
    > * { 
        margin: auto;
    }
}

.text-wrapper {
    text-align: center;
}

.footer {
    position: fixed;
    width: 100%;
    @include flexbox(row, wrap, space-between, flex-start);
    bottom: 0;
    padding: 20px;
    
    svg {
        width: 25px;
        height: 25px;
    }
    
    @media screen and (max-width: 550px) {
        @include flexbox(column, wrap, flex-start, flex-start);
    }
}

.footer__left {
    @media screen and (max-width: 550px) {
        margin-bottom: 20px;
    }
}

.footer__anchor {
    @include flexbox(row, wrap, flex-start, center);
}

.footer__icon {
    font-size: 25px;
    margin-right: 10px;
    display: block;
    @media screen and (max-width: 550px) {
        order: 1;
    }
}

.footer__phone,
.footer__mail {
    @media screen and (max-width: 550px) {
        order: 2;
    }
}

.footer__icon-wrapper {
    .footer__right & {
        margin-left: 10px;
        margin-top: -10px;
    }
}

.pop-up {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background: $color-bg;
    transform: translateY(500px); 
    opacity: 0;
    transition: all 0.25s ease-in-out;

    &.active--ani {
        transform: translateY(0px);
        opacity: 1;
        transition: all 0.25s ease-in-out;
    }

    &.active {
        display: block;
    }
}

.pop-up__inner {
    width: 80%;
    max-width: 500px;
    margin: auto;
    padding-top: 40vh;

    p, 
    img {
        margin-bottom: 20px;
    }

    p,
    img {
        width: 100%;
    }
}